<template>
  <div id="trigger-video__video" class="trigger-video__video">
    <div class="playback-animation" id="playback-animation">
      <svg class="playback-icons">
        <use class="hidden" href="#play-icon"></use>
        <use href="#pause"></use>
      </svg>
    </div>

    <video
      id="video"
      class="video__estilo contenedor__video"
      muted
      controls
      preload="metadata"
      :poster="bloque_dos.poster"
    >
      <source
        :src="bloque_dos.url_video"
        type="video/mp4"
      />
    </video>

    <div class="video-controls" id="video-controls">
      <div class="video-progress">
        <progress id="progress-bar" value="0" min="0"></progress>
        <input
          class="seek"
          id="seek"
          aria-label="Seek"
          value="0"
          min="0"
          type="range"
          step="1"
        />
        <div class="seek-tooltip" id="seek-tooltip">00:00</div>
      </div>

      <div class="bottom-controls">
        <div class="left-controls">
          <button data-title="Play" role="button" aria-label="Play" id="play">
            <svg class="playback-icons">
              <use href="#play-icon"></use>
              <use class="hidden" href="#pause"></use>
            </svg>
          </button>

          <div class="volume-controls">
            <button
              data-title="Mute"
              role="button"
              aria-label="Mute"
              class="volume-button"
              id="volume-button"
            >
              <svg>
                <use class="hidden" href="#volume-mute"></use>
                <use class="hidden" href="#volume-low"></use>
                <use href="#volume-high"></use>
              </svg>
            </button>

            <input
              class="volume"
              id="volume"
              aria-label="Volume"
              value="0"
              data-mute="0.5"
              type="range"
              max="1"
              min="0"
              step="0.01"
            />
          </div>

          <div class="time">
            <time id="time-elapsed">00:00</time>
            <span> / </span>
            <time id="duration">00:00</time>
          </div>
        </div>
        <!--left-controls-->
      </div>
      <!--bottom-controls-->
    </div>
    <!--video-controls-->
    <img
      loading="lazy"
      class="scroll_video__flecha"
      src="https://d3jotkb1j9zbaa.cloudfront.net/flecha-abajo.svg"
      alt="Icono flecha abajo"
      title="Icono flecha abajo"
    />
  </div>
  <!--trigger-video__video-->

  <svg style="display: none">
    <defs>
      <symbol id="pause" viewBox="0 0 24 24">
        <path
          d="M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z"
        ></path>
      </symbol>

      <symbol id="play-icon" viewBox="0 0 24 24">
        <path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z"></path>
      </symbol>

      <symbol id="volume-high" viewBox="0 0 24 24">
        <path
          d="M14.016 3.234q3.047 0.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-0.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484v-2.063zM16.5 12q0 2.813-2.484 4.031v-8.063q1.031 0.516 1.758 1.688t0.727 2.344zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z"
        ></path>
      </symbol>

      <symbol id="volume-low" viewBox="0 0 24 24">
        <path
          d="M5.016 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6zM18.516 12q0 2.766-2.531 4.031v-8.063q1.031 0.516 1.781 1.711t0.75 2.32z"
        ></path>
      </symbol>

      <symbol id="volume-mute" viewBox="0 0 24 24">
        <path
          d="M12 3.984v4.219l-2.109-2.109zM4.266 3l16.734 16.734-1.266 1.266-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-0.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016h-3.984v-6h4.734l-4.734-4.734zM18.984 12q0-2.391-1.383-4.219t-3.586-2.484v-2.063q3.047 0.656 5.016 3.117t1.969 5.648q0 2.203-1.031 4.172l-1.5-1.547q0.516-1.266 0.516-2.625zM16.5 12q0 0.422-0.047 0.609l-2.438-2.438v-2.203q1.031 0.516 1.758 1.688t0.727 2.344z"
        ></path>
      </symbol>
    </defs>
  </svg>
</template>

<script>
import { inject } from '@vue/runtime-core';
export default {
  name: "videoBloqueDos",
  mounted() {
    this.cargarVideo();
  },
  setup() {
    const bloque_dos = inject('jsonHome').value.bloque_dos
    return {bloque_dos }
  },
  methods: {
    cargarVideo() {
      const video = document.getElementById("video");
      const videoControls = document.getElementById("video-controls");
      const playButton = document.getElementById("play");
      const playbackIcons = document.querySelectorAll(".playback-icons use");
      const timeElapsed = document.getElementById("time-elapsed");
      const duration = document.getElementById("duration");
      const progressBar = document.getElementById("progress-bar");
      const seek = document.getElementById("seek");
      const seekTooltip = document.getElementById("seek-tooltip");
      const volumeButton = document.getElementById("volume-button");
      const volumeIcons = document.querySelectorAll(".volume-button use");
      const volumeMute = document.querySelector('use[href="#volume-mute"]');
      const volumeLow = document.querySelector('use[href="#volume-low"]');
      const volumeHigh = document.querySelector('use[href="#volume-high"]');
      const volume = document.getElementById("volume");
      const playbackAnimation = document.getElementById("playback-animation");
      const videoContainer = document.getElementById("trigger-video__video");
      const videoWorks = !!document.createElement("video").canPlayType;

      if (videoWorks) {
        video.controls = false;
        videoControls.classList.remove("hidden");
      }
      function togglePlay() {
        if (video.paused || video.ended) {
          video.play();
        } else {
          video.pause();
        }
      }
      function updatePlayButton() {
        playbackIcons.forEach((icon) => icon.classList.toggle("hidden"));
        if (video.paused) {
          playButton.setAttribute("data-title", "Play");
        } else {
          playButton.setAttribute("data-title", "Pause");
        }
      }
      function formatTime(timeInSeconds) {
        const result = new Date(timeInSeconds * 1000)
          .toISOString()
          .substr(11, 8);
        return {
          minutes: result.substr(3, 2),
          seconds: result.substr(6, 2),
        };
      }
      function initializeVideo() {
        const videoDuration = Math.round(video.duration);
        seek.setAttribute("max", videoDuration);
        progressBar.setAttribute("max", videoDuration);
        const time = formatTime(videoDuration);
        duration.innerText = `${time.minutes}:${time.seconds}`;
        duration.setAttribute("datetime", `${time.minutes}m ${time.seconds}s`);
      }
      function updateTimeElapsed() {
        const time = formatTime(Math.round(video.currentTime));
        timeElapsed.innerText = `${time.minutes}:${time.seconds}`;
        timeElapsed.setAttribute(
          "datetime",
          `${time.minutes}m ${time.seconds}s`
        );
      }

      // updateProgress indicates how far through the video
      // the current playback is by updating the progress bar
      function updateProgress() {
        seek.value = Math.floor(video.currentTime);
        progressBar.value = Math.floor(video.currentTime);
      }
      function updateSeekTooltip(event) {
        const skipTo = Math.round(
          (event.offsetX / event.target.clientWidth) *
            parseInt(event.target.getAttribute("max"), 10)
        );
        seek.setAttribute("data-seek", skipTo);
        const t = formatTime(skipTo);
        seekTooltip.textContent = `${t.minutes}:${t.seconds}`;
        const rect = video.getBoundingClientRect();
        seekTooltip.style.left = `${event.pageX - rect.left}px`;
      }
      function skipAhead(event) {
        const skipTo = event.target.dataset.seek
          ? event.target.dataset.seek
          : event.target.value;
        video.currentTime = skipTo;
        progressBar.value = skipTo;
        seek.value = skipTo;
      }
      function updateVolume() {
        if (video.muted) {
          video.muted = false;
        }
        video.volume = volume.value;
      }
      function updateVolumeIcon() {
        volumeIcons.forEach((icon) => {
          icon.classList.add("hidden");
        });
        volumeButton.setAttribute("data-title", "Mute");
        if (video.muted || video.volume === 0) {
          volumeMute.classList.remove("hidden");
          volumeButton.setAttribute("data-title", "Unmute");
        } else if (video.volume > 0 && video.volume <= 0.5) {
          volumeLow.classList.remove("hidden");
        } else {
          volumeHigh.classList.remove("hidden");
        }
      }
      function toggleMute() {
        video.muted = !video.muted;
        if (video.muted) {
          volume.setAttribute("data-volume", volume.value);
          volume.value = 0;
        } else {
          volume.value = volume.dataset.volume;
        }
      }
      function animatePlayback() {
        playbackAnimation.animate(
          [
            {
              opacity: 1,
              transform: "scale(1)",
            },
            {
              opacity: 0,
              transform: "scale(1.3)",
            },
          ],
          {
            duration: 500,
          }
        );
      }
      function toggleFullScreen() {
        if (document.fullscreenElement) {
          document.exitFullscreen();
          document.getElementById("close").style.display = "flex";
        } else if (document.webkitFullscreenElement) {
          document.webkitExitFullscreen();
          document.getElementById("close").style.display = "flex";
        } else if (videoContainer.webkitRequestFullscreen) {
          videoContainer.webkitRequestFullscreen();
          document.getElementById("close").style.display = "none";
        } else {
          videoContainer.requestFullscreen();
          document.getElementById("close").style.display = "none";
        }
      }
      function hideControls() {
        if (video.paused) {
          return;
        }
        videoControls.classList.add("hide");
      }
      function showControls() {
        videoControls.classList.remove("hide");
      }
      playButton.addEventListener("click", togglePlay);
      video.addEventListener("play", updatePlayButton);
      video.addEventListener("pause", updatePlayButton);
      video.addEventListener("loadedmetadata", initializeVideo);
      video.addEventListener("timeupdate", updateTimeElapsed);
      video.addEventListener("timeupdate", updateProgress);
      video.addEventListener("volumechange", updateVolumeIcon);
      video.addEventListener("click", togglePlay);
      video.addEventListener("click", animatePlayback);
      video.addEventListener("mouseenter", showControls);
      video.addEventListener("mouseleave", hideControls);
      videoControls.addEventListener("mouseenter", showControls);
      videoControls.addEventListener("mouseleave", hideControls);
      seek.addEventListener("mousemove", updateSeekTooltip);
      seek.addEventListener("input", skipAhead);
      volume.addEventListener("input", updateVolume);
      volumeButton.addEventListener("click", toggleMute);
    },
  },
};
</script>